.image-checkbox-container {
  .image-label {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &::before {
      background-color: transparent;
      content: " ";
      display: block;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
      transition: all 200ms ease;
      transform: scale(0);
    }

    & > * {
      object-fit: cover;
    }

    span {
      transition-duration: 0.2s;
      transform-origin: 50% 50%;
    }
  }

  .image-checkbox:checked {
    & + .image-label {
      border-radius: 50%;
      border: 2px solid #fc741e;
      opacity: 1;
    }

    & + .image-label::before {
      content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='13' height='13' rx='6.5' fill='%23FC741E'/%3E%3Cpath d='M10.3307 4.5L5.7474 9.08333L3.66406 7' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.5' y='0.5' width='13' height='13' rx='6.5' stroke='%23FC741E'/%3E%3C/svg%3E%0A");
      transform: scale(1);
    }
  }
}
