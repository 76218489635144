.task-at-hand {
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    display: inline-block;
    border-radius: 400px;
    border: 1px solid #f2f2f2;
    padding: 5px;
    margin-left: -6px;
    margin-top: -5px;
  }

  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    transform: scale(0.8);
    border-radius: 400px;
    background-color: #989898;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    display: inline-block;
    border-radius: 400px;
    border: 1px solid #f2f2f2;
    padding: 5px;
    margin-left: -6px;
    margin-top: -5px;
  }

  .ant-steps-item-wait
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    transform: scale(0.8);
    border-radius: 400px;
    background-color: #989898;
  }

  &.ant-steps-vertical.ant-steps-dot
    .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    height: 24px;
    margin-top: 1px;
    margin-inline-start: 4px;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background: #e2e2e2;
  }
}
