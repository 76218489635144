.ant-notification.ant-notification-bottomRight {
  left: 50%;
  bottom: 0 !important;
  margin-right: 30%;
  transform: translate(-50%, -50%);
}

.under25-notification {
  &.auto-close {
    .ant-notification-notice-message {
      margin: 0;
    }
  }
}
