.user-lists-container {
  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #d2d2d2;
  }
}

.user-list {
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }

  .ant-checkbox-inner:after {
    width: 5px;
    height: 8px;
    inset-inline: 6px 4px !important;
  }
}
