.interest-select-tags {
  .ant-select-selector {
    padding-inline-end: 0px !important;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selector {
    border-color: #f2f2f2 !important;
    padding: 16px 20px;
    border-radius: 8px;
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    inset-inline-start: 20px;
  }
  .ant-select-selection-search {
    margin-inline-start: 0px;
  }
  .ant-select-selection-overflow {
    gap: 8px;
  }
  .ant-select-selection-item {
    margin-inline-end: 0px;
  }
  .ant-select-selection-item {
    height: 28px;
    background-color: #fc741e14;
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    border-radius: 16px;
    transition: background-color 0.4s;

    &:hover {
      background-color: #ffeee4;
    }
  }
  .ant-select-selection-item-content {
    display: flex;
    align-items: center;
    margin-inline-end: 6px;
    color: #fc741e;
    height: fit-content;
    padding: 4px 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.1;
  }
  .anticon-close {
    transition: background-color 0.4s;
    border-radius: 50%;
    padding-bottom: 2px;
    height: fit-content;
    &:hover {
      // background-color: #FC741E;
    }
    svg {
      width: 12px;
      height: 12px;
      fill: #fc741e;
    }
  }
  .ant-select-selection-placeholder {
    color: #a4a4a4;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.2;
  }
}

.suggestion-close {
  display: none;
}

.interest-select-tags.is-mac,
.interest- {
  .ant-select-selection-item {
    padding-top: 4px !important;
  }
}
