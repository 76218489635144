.feed-modal {
  .ant-modal-content {
    padding: 0;
  }
}

.ant-modal-root .ant-modal-wrap {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px;
    transition: all 300ms ease;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #d2d2d2;
    }
  }
}
