$primary-color: #1f1f1f;
$secondary-color: #ff6100;
$gray-color: #666666;

.ant-input.otp-input {
  position: relative;
  width: 60px;
  height: 60px;
  padding: 20px;
  color: $primary-color;
  font-family: "OskariG2Regular";
  font-size: 17px;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  vertical-align: middle;

  &:hover {
    border-color: rgba(102, 102, 102, 0.4);
    // @apply border-gray_1_hover;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(109, 109, 109, 0.1);
    border-color: rgba(102, 102, 102, 0.8);
    // @apply shadow-input border-gray_3_hover;
  }

  &::placeholder {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 8px;
    height: 4.5px;
    text-align: center;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='2' viewBox='0 0 8 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.153 0.288H7.463V1.631H0.153V0.288Z' fill='%23666666' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
    // background-size: 8px 20px;
    background-repeat: no-repeat;
  }
}

.orange-underline {
  color: $secondary-color;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-decoration: underline;
  text-underline-offset: 1px;
  // @apply text-U25_orange text-[17px] not-italic font-medium leading-[120%] underline underline-offset-1;
}

.text-gray {
  color: $gray-color;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  // @apply text-gray_1 text-[17px] not-italic font-normal leading-[120%];
}
