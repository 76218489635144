.banner-card-btn-icon{
    svg{
     path{
        stroke: white;
     }   
    }
}

.lockOutlined-icon{
    width: 16px;
    height: 16px;
    path{
        stroke:#1F1F1F;
    }
}