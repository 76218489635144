.custom-modal {
  font-family: "OskariG2Regular";

  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-close {
    width: 24px;
    height: 24px;
    top: 24px;
    right: 24px;
  }
}
