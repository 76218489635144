.primary-btn {
  font-family: "OskariG2Regular";
}

.font-primary {
  color: white;
  font-size: 17px;
  font-weight: 400;
  line-height: 1;
  // @apply text-white text-[17px] font-normal leading-none;
}

.font-dark {
  color: #1f1f1f;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // @apply text-primary text-[17px] not-italic font-normal leading-[normal];
}

.ant-btn-default.primary-btn:not(:disabled):not(.ant-btn-disabled):not(
    .no-hover
  ):hover {
  color: white;
  border-color: #1f1f1f;
}
