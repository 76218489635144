.custom-popover {
  .ant-popover-title {
    margin-bottom: 0 !important;
  }
}


.sort-button{
  &.ant-popover-open{
background-color: #1F1F1F !important;
svg{
path{
  stroke:white;
}
}
  }
}