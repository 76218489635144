.onboarding-carousel.owl-carousel {
  &.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
    text-align: left;
  }
  .diamond-dot {
    margin-right: 15px;

    span {
      display: block;
      width: 8px;
      height: 8px;
      background-color: rgba(31, 31, 31, 0.4);
      transform: rotate(45deg);
      // @apply block w-2 h-2 bg-gray-500 rotate-45;
    }

    &.active span {
      display: block;
      width: 8px;
      height: 8px;
      background-color: #1f1f1f;
      transform: rotate(45deg);
      // @apply block w-2 h-2 bg-primary rotate-45;
    }
  }
}
