.header-dropdown {
  ul {
    font-family: "OskariG2Regular";
  }
}

.ant-notification.ant-notification-topRight {
  width: fit-content;
  height: fit-content !important;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 10% !important;
  transform: translate(-50%, -50%);
  &.auto-close {
    .ant-notification-notice-message {
      margin: 0;
    }
  }
}

.ant-notification-notice.copylink_notification {
  padding: 5px 8px 12px 10px !important;
}
