.ant-tabs.reaction-modal {
  .ant-tabs-tab-btn {
    color: #808080;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
    margin-bottom: 24px;
  }

  .ant-tabs-tab {
    padding: 16px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: #fc741e;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #101828;
  }
}

.reaction {
  .ant-modal-close {
    width: 24px;
    height: 24px;
    top: 16px;
    right: 24px;
  }
}
