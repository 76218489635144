.story {
  .user-stories-carousel {
    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
      background: #56423d;
      transform: translate3d(0px, -50%, 0px) scale(0.8);
    }
    .owl-item {
      margin: 0 auto;
      opacity: 0.5 !important;
      background: transparent !important;
      border-radius: 14.759px !important;

      height: calc(100vh - 150px) !important;

      width: fit-content;
      transform: scale(0.8);
      transition: transform 600ms ease-out;

      @media screen and (min-width: 992px) {
        transform: scale(0.6) translate(0px, 0);
      }
      @media screen and (min-width: 1024px) {
        transform: scale(0.6) translate(0px, 0);
      }

      @media screen and (min-width: 1200px) {
        transform: scale(0.6) translate(0%, 0);
      }
    }

    .owl-item.active.center {
      background: transparent !important;

      opacity: 1 !important;
      transform: scale(0.8) !important;

      @media screen and (min-width: 992px) {
        transform: scale(1) translate(0px, 0);
      }

      @media screen and (min-width: 1024px) {
        transform: scale(1) translate(0px, 0);
      }

      @media screen and (min-width: 1200px) {
        transform: scale(1) translate(0%, 0);
      }
    }
    .owl-carousel .owl-dots {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
    .owl-carousel .owl-dots .owl-dot {
      display: inline-block;
    }
    .owl-carousel .owl-dots .owl-dot span {
      background: #56423d;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 2px 5px;
      transform: translate3d(0px, -50%, 0px) scale(0.3);
      transform-origin: 50% 50% 0;
      transition: all 250ms ease-out 0s;
    }
  }
}

.owl-carousel {
  .owl-nav {
    .disabled {
      display: none;
    }
    .prev-btn {
      span {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Cpath d='M15 30L25 20L15 10' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        transform: rotate(180deg);
      }
    }
    .next-btn {
      span {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Cpath d='M15 30L25 20L15 10' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      }
    }
  }
}

.ant-modal-root .ant-modal-wrap {
  overflow-y: hidden;
}

.ant-modal-wrap {
  margin: 30px !important;
}

.ant-modal-wrap {
  border-radius: 8px !important;
}
