.quill {
  position: relative;

  .ql-container {
    border: none;
    position: relative;
    .ql-editor {
      padding: 0 !important;
      margin: 20px 0;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 20.4px */
      text-transform: capitalize;
      font-style: normal !important;
    }
    .ql-editor .ql-blank {
      padding: 0;
    }

    .ql-editor.ql-blank::before {
      color: #c3c3c3 !important;
      font-size: 17px;
      padding: 0;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      font-family: "OskariG2Regular";
      text-transform: capitalize;
      left: 0;
    }
  }

  .ql-toolbar {
    display: none;
    position: absolute;
    background-color: white;
    z-index: 999;
    border-radius: 4px;
    top: 100%;
    border: none;
    right: -11%;
    bottom: -48px;
    box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.15);

    transform: translateX(-50%);
  }

  &.text-selected .ql-toolbar {
    display: block;
  }
}
