.account-dropdown {
  .ant-dropdown-menu {
    padding: 0 24px !important;
  }
  .ant-dropdown-menu-item {
    padding: 8px !important;
    border-radius: 8px !important;
    border-bottom: 1px solid #f2f2f2;
    &:last-child {
      border: none;
    }
  }
}
