.avatar-comp{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 39px;

    .ant-avatar {
        border: none;
        height: auto;
        width: auto;
    }
    .ant-avatar  {
        margin-inline-start: 0px !important;
        margin-inline-end: -16px ;
    }
    }
