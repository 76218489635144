.header {
  .menu-container {
    @media screen and (min-width: 1220px) {
      max-width: 934px;
    }
  }

  .nav-items-container {
    @media screen and (max-width: 1024px) {
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;
      padding: 0 32px;
      background-color: white;
      z-index: 999;
      right: calc(-100% - 32px);
      transition: right 0.3s ease-in-out;

      &.visible {
        right: 0;
      }

      .nav-item {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }

  .headerSearch.is-mac {
    .ant-input {
      margin-top: 2px !important;

      &::placeholder {
        padding-top: 4px !important;
      }
    }
  }
}
