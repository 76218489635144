.owl-theme.community-carousel {
  .white-dark-dot {
    &:not(:last-child) {
      margin-right: 6px;
    }

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(31, 31, 31, 0.4);
      transform: rotate(45deg);
    }

    &.active span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #1f1f1f;
      transform: rotate(45deg);
    }
  }
}
