.settings-tab{

    // tab navbar
    gap: 24px;
    background-color: #F2F2F2;
    .ant-tabs-nav{
        min-width: 300px;
        @media screen and (max-width:996px) {
min-width: auto;
    }
}
    .ant-tabs-extra-content{
        width: 100%;
        margin-bottom: 24px;
    }
    .ant-tabs-nav{
        padding: 24px;
        background-color: white;
        border-radius: 12px;
        height: 100%;
    }
    .ant-tabs-nav-list{
        gap: 8px;
    }
    .ant-tabs-tab{
        border-radius: 8px !important;
        border: none !important;
        padding: 16px !important;
        height: 42px;
        background-color: white !important;
        &:hover{
            .ant-tabs-tab-btn{
                color: #1F1F1F !important;
            }
        }
    }
    .ant-tabs-tab-btn{
        transition: color .4s;
        color: #A4A4A4 !important;
font-size: 17px;
font-weight: 400;
line-height: 1.2;
    }
   .ant-tabs-tab-active{
background-color: #F2F2F2 !important;
.ant-tabs-tab-btn{
    color: #1F1F1F !important;
font-weight: 500;
}
    }


    // tab content
    .ant-tabs-content-holder{
min-width: 716px;
background-color: white;
border-left: none;
border-radius: 12px;
padding: 24px;

@media screen and (max-width:1024px) {
    min-width: 500px;

    }
    .ant-tabs-tabpane {
padding-left: 0px !important;
    }

}
    }