.custom-datepicker {
  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__month-container {
    width: 100%;
    font-family: "OskariG2Regular";
  }

  .react-datepicker {
    width: 100%;
    border: 0;
  }

  .react-datepicker__header {
    width: 100%;
    background-color: white;
    border: 0;
    padding: 0;
  }

  .react-datepicker__day-names {
    margin-bottom: 2px;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-names {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 44px;
      height: 44px;
      margin: 0;

      //Font style
      color: #1f1f1f;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-grid;
    place-content: center;
    width: 44px;
    height: 44px;
    margin: 0;
    border-radius: 50%;

    //Font style
    color: #666;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #90caf9;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    // border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    // border-radius: 50%;
    background-color: #90caf9;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 50%;
  }
}

.custom-year-datepicker {
  .react-datepicker__year--container {
    font-family: "OskariG2Regular" !important;
  }
  .react-datepicker__header .react-datepicker-year-header {
    font-weight: 400 !important;
  }
  .react-datepicker__year-wrapper {
    max-width: 214px !important;
  }
  .react-datepicker__year-wrapper {
    margin-top: 15px !important;
  }
  .react-datepicker__year-text {
    font-size: 1rem;
    padding: 4px 0;
    &:hover {
      border-radius: 5px !important;
    }
  }
  .react-datepicker__navigation--next {
    top: -5px !important;
    right: 0px !important;
  }
  .react-datepicker__navigation--previous {
    left: 0px !important;
    top: -5px !important;
  }
  .react-datepicker__triangle {
    visibility: hidden;
  }
  .react-datepicker__year-wrapper {
    gap: 5px !important;
  }
  .react-datepicker__year-text
    .react-datepicker__year-text--selected
    .react-datepicker__year-text--today
    .react-datepicker__year-text
    .react-datepicker__year-text--disabled {
    &:hover {
      border-radius: none !important;
    }
  }
  .react-datepicker__day-names {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 44px;
      height: 44px;
      margin: 0;

      //Font style
      color: #1f1f1f;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    top: 10px !important;
  }
}
