.ant-upload-wrapper.photo-upload {
  font-family: "OskariG2Regular";

  &.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: auto;
    height: auto;
    border: 0;
    background-color: transparent;
  }

  &.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
    border-color: #1f1f1f;
    // @apply border-primary;
  }

  &.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container {
    width: 80px;
    height: 80px;
    margin: 0;
    margin-right: 16px;
    border-radius: 12px;
    border: none !important;
    // @apply border border-solid border-gray_2;
  }

  &.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail
    img {
    border-radius: 8px;
  }

  .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
  }

  &.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item::before {
    background-color: transparent;
    z-index: 0;
  }

  .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: transparent;
  }
}

.thread-upload {
  &.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container {
    width: 100% !important;
    height: auto !important;
    margin: 0;
    margin-right: 0 !important;
    border-radius: 12px;
    border: none !important;
  }

  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail {
    width: 535px;
    height: 312px;
    border-radius: 12px;
    object-fit: cover !important;
  }

  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail
    img {
    object-fit: cover !important;
  }
}

.ant-modal-wrap.img-crop-modal .ant-btn-primary {
  color: #fff;
  background-color: black !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  line-height: 100% !important;
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
:where(.css-dev-only-do-not-override-nnuwmp).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: none !important;
  border-radius: 8px;
}
