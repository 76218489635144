.progress-bar{
    margin-bottom: 0px;

    .ant-progress-text{
        margin-inline-start: 12px;
    }
    .ant-progress-outer{
        margin-inline-end: calc(-2em - 12px) !important;
        padding-inline-end: calc(2em + 12px) !important;
    }
    .ant-progress-inner{
        background-color: #DBD9D9;
    }
    .ant-progress-bg{
        background-color: #001EFF;
    }
}