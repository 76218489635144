.edit-profile-tab {
  .ant-tabs-tab-btn {
    color: #a4a4a4;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: capitalize;
    transition: all 0.4s;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #1f1f1f !important;
    }
  }
  .ant-tabs-ink-bar {
    background: #1f1f1f;
  }
  .ant-tabs-tab {
    display: flex;
    justify-content: center;
    padding: 16px 10px;
    min-width: 180px;
    text-align: center;
    &:hover {
      .ant-tabs-tab-btn {
        color: #1f1f1f;
      }
    }
  }
  .ant-tabs-tab {
    &:first-child {
      margin-left: 0px;
    }
    margin-left: 100px;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    margin: 10px 0;
    padding: 14px 56px;
    overflow-y: auto;
  }
}

.personal-tab-input {
  padding: 16px 16px 16px 44px !important;
}
.personal-info-dropdown {
  .ant-select-selector {
    height: 44px !important;
    padding: 0 16px !important;
    box-shadow: none !important;
    &:hover {
      border-color: #1f1f1f !important;
    }
  }
  .ant-select-arrow {
    margin-right: 4px;
  }
  .ant-select-selection-item {
    color: #1f1f1f;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.2 !important;
    margin-top: 10px;
  }
}
.ant-select-focused .ant-select-selector {
  border-color: #1f1f1f !important;
}

.personal-info-popup {
  max-height: 100% !important;
  overflow-y: hidden;
}

.personal-info-textarea {
  resize: none !important;
  padding: 12px 16px;
  height: 100px !important;
  color: #1f1f1f;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.2;
  &:hover,
  &:focus {
    border-color: #1f1f1f;
    box-shadow: none;
  }

  &::placeholder {
    color: rgba(102, 102, 102, 0.6);
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
  }
}

.add-college-number {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  width: 100%;
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.personal-info-btn {
  &:hover {
    border-color: #1f1f1f !important;
    color: #1f1f1f !important;
  }
}

.profile-photo-upload {
  .ant-upload-list-item-thumbnail {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .ant-upload-wrapper
    .ant-upload-list.ant-upload-list-picture
    .ant-upload-list-picture-card,
  .ant-upload-list-item {
    padding: 0px !important;
    border: none !important;
    max-width: 100px;
    min-width: 100px;
    max-height: 100px;
    min-height: 100px;
  }
  .ant-upload-list-item-thumbnail {
    a {
      max-width: 100px;
      min-width: 100px;
      max-height: 100px;
      min-height: 100px;
      border-radius: 16px;
    }
  }

  .ant-upload-list-item-container {
    margin: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .ant-upload-list-item::before {
    width: 100% !important;
    height: 100% !important ;
  }
}
