.search-bar {
  .ant-input-prefix {
    height: 16px;
    width: 16px;
    margin-inline-end: 12px;
    vertical-align: middle;
  }

  input[class~="ant-input"] {
    font-family: "OskariG2Regular";
    line-height: 16px;
    height: 16px;
    vertical-align: middle;
    color: #1f1f1f;
    font-size: 1rem;
    line-height: normal;
    font-style: normal;
    font-weight: 400;
    // @apply text-primary text-base not-italic font-normal leading-[normal];

    &::placeholder {
      font-family: "OskariG2Regular";
      font-size: 1rem;
      line-height: 1.5rem;
      font-style: normal;
      font-weight: 400;
      color: rgba(31, 31, 31, 0.6);
      // @apply text-[rgba(31,31,31,0.60)] text-base not-italic font-normal leading-[normal];
    }
  }

  &:hover {
    border-color: rgba(102, 102, 102, 0.4) !important;
    // @apply border-gray_1_hover #{!important};
  }

  &:focus-within {
    box-shadow: 0 0 0 2px rgba(109, 109, 109, 0.1) !important;
    border-color: rgba(102, 102, 102, 0.8) !important;
    // @apply shadow-input border-gray_3_hover #{!important};
  }

  .ant-input-clear-icon {
    &:hover {
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
}
