.edit-wrapper{
    &:hover{
        .edit-icon{
visibility: visible;
opacity: 1;
        }
    }
    .edit-icon{
    visibility: hidden;
    opacity: 0;
    transition: all .4s;
    border-radius: 6px;
    padding: 3px;
    

    &:hover{
        background-color: #F4F4F4;

    }
                }

}

.file-wrapper{
    .download-icon{
        visibility: hidden;
        opacity: 0;
        transition: all .4s;
    }
    &:hover{
        .download-icon{
            visibility: visible;
            opacity: 1;
        }
    }
}



// antd 
.social-link-input{
padding: 16px !important;
max-height: 44px;
border-radius: 8px;
border-color: #DBD9D9;
font-size: 17px;
font-weight: 400;
line-height: 120%;
        &:hover{
            border-color: #66666699;
        }
        &:focus{
            border-color: #66666699;
            box-shadow: none;
        }
        &::placeholder{
            color: rgba(102, 102, 102, 0.80);
font-size: 13px;
font-weight: 400;
line-height: 1.2;
        }
    
}



.social-link-dropdown{
.ant-select-selector{
    height: 44px !important;
    padding:   0 16px !important;
    border-color: #DBD9D9 !important;
    box-shadow: none !important;
    min-width: 130px;

    &:hover{
        border-color: #66666699 !important;
    }
}

.ant-select-selection-item{
    margin-top: 6px;
color: #1F1F1F;
font-size: 17px;
font-weight: 400;
line-height: 1.2;
}

}

.social-link-dropdown-popup{
    padding: 8px;
    min-width: 260px !important; 
    width: auto;
    max-height: 172px;
    overflow-y: scroll;

    .rc-virtual-list-holder-inner{
        gap: 4px;
    }
    .ant-select-item {
padding: 8px;
    }

    .ant-select-item-option-content{
        color:  #1F1F1F;
font-size: 17px;
font-weight: 400;
line-height: 1.2;
    }
    .ant-select-item-option-active{
        background-color: #F2F2F2 !important;
    }
   
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background-color: #F2F2F2;
    }
}

.add-skill-input{
    padding: 16px ;
    max-height: 52px;
    border-radius: 8px;
    border-color: #F2F2F2;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
    &:hover{
        border-color: #66666699;
    }
    &:focus{
        border-color: #66666699;
        box-shadow: none;
    }
    &::placeholder{
        color: rgba(102, 102, 102, 0.80);
font-size: 17px;
font-weight: 400;

line-height: 1.2;
    }
}


.skill-slider{
 .ant-slider-rail{
    background-color: #F2F2F2;
    height: 8px;
    border-radius: 16px;
 }   
 .ant-slider-track{
    height: 8px;
    background-color: #FC741E !important;
    border-radius: 16px;
 }
 .ant-slider-step{
    height: 8px;
 }
 .ant-slider-handle{
    inset-block-start: 0px;
    &::after{
        box-shadow: 0 0 0 2px #FC741E;
        width: 16px;
        height: 16px;
    }
    &:hover{
        &::after{
            width: 16px;
            height: 16px;
            box-shadow: 0 0 0 4px #FC741E;
        }
    }
    &:focus{
        &::after{
            width: 16px;
            height: 16px;
            box-shadow: 0 0 0 4px #FC741E;
        }
    }
 }
}


.experience-date-picker{
    padding: 16px;
    max-height: 44px;
    width: 100%;
    // border-color: #666666CC;
    &.ant-picker-focused{
        border-color: #1F1F1F !important;
        box-shadow:  0 0 2px #1F1F1F;
    }
    &:hover{
        border-color: #1F1F1F;
    }
    .ant-picker-input{
       input{
        color:  #1F1F1F;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 1.2;
       }
    }
}

.experience-date-picker-popup{
     .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner  {
    background: #90caf9;
    color: white !important;
  }
  .ant-picker-cell-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666 !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5 !important;
  }


}


.add-resume-upload{
    .ant-upload .ant-upload-btn{
        padding: 25px 0;
    }
      .ant-upload-drag{
        &:hover{
            border-color: #1F1F1F !important; 
        }
     }
     .ant-upload-list-item {
margin-top: 16px !important;
padding: 12px 16px;
background-color: white;
border: 1px solid #DBD9D9;
border-radius: 8px;
height: 44px !important;
     }
     .ant-upload-list-item-name{
        color: #1F1F1F !important;
font-size: 15px;
font-weight: 400;
line-height: 1.2 !important;
     }
     .anticon {
color: #1F1F1F !important;
     }
}


.month-picker{
    .ant-picker-header{
        display: none;
    }
}