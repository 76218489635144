

.empty-state{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    .ant-empty-image{
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 72px;
        max-width: 72px;
        min-height: 72px;
        min-width: 72px;
        border-radius: 400px;
        border: 1px solid #F2F2F2;
        img{
            max-width: 32px;
            min-width: 32px;
            max-height: 32px;
            min-height: 32px;
        }
    }
}