@import "../../styles/fonts.css";

.ant-input.custom-input {
  color: #1f1f1f !important;
  font-family: "OskariG2Regular";

  &.is-mac {
    padding-top: 20px !important;
  }

  &::placeholder {
    color: rgba(102, 102, 102, 0.8);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &:hover {
    border-color: rgba(102, 102, 102, 0.4) !important;
    // @apply border-gray_1_hover #{!important};
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(109, 109, 109, 0.1) !important;
    border-color: rgba(102, 102, 102, 0.8) !important;
    // @apply shadow-input border-gray_3_hover #{!important};
  }
}
