@font-face {
  font-family: "OskariG2Regular";
  src: url("../assets/fonts/oskari-g2-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OskariG2Medium";
  src: url("../assets/fonts/Oskari+G2+Medium.otf") format("opentype");
  font-weight: medium;
  font-style: normal;
}
