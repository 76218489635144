.container {
  height: fit-content;
  // line-height: 200px;
  text-align: center;
  min-width: 40px !important;
}
.search-form {
  right: 200px;
  top: 200px;
}

.search-field {
  background-color: transparent;
  background-image: url(../../../public/images/svg/search-lg.svg);
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: none;
  margin-left: 7px !important;
  cursor: pointer;

  height: 40px;
  padding: 0 0 0 27px;
  position: relative;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition: width 400ms ease, background 400ms ease;
  width: 0px;
  cursor: pointer;
  + svg {
    visibility: hidden;
  }
}

.search-field:not(:placeholder-shown),
.search-field:focus {
  background-color: transparent !important;
  padding-left: 30px;
  margin-left: 4px !important;

  // border: 1px solid var(--grey2, #dbd9d9);

  border-radius: 8px;
  cursor: text;
  outline: 0;
  width: calc(100vw - 120px);
  color: #000;
}
.search-form .search-submit {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  // visibility: hidden;
  content: url(../../assets/svg/x-close.svg);
  margin-right: 12px;

  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  // position: absolute;
  // right: 16;
  border-radius: 50%;

  &:hover {
    background: #f8f8f8 !important;
    cursor: pointer;
  }
}

.input::-webkit-search-cancel-button:after {
  // content: "✖️";
  // color: green;
  // /* 	outline: 1px solid red; */
  // /* 	border: 1px solid red; */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // tranform: translate(-50%, -50%);
}
