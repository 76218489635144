.search-select{
    .ant-select-selection-search{
        top: 6px !important;
        left: 16px !important;
        input{
            color: #1F1F1F !important;
font-size: 17px;
font-weight: 400;
line-height: 1.2;
        }
    }
    .ant-select-selection-search-input{
        padding-right: 20px !important;

    }
    .ant-select-selection-placeholder{
        margin-top: 7px;
        color: rgba(102, 102, 102, 0.60);
font-size: 17px;
font-weight: 400;
line-height: 1.2;
    }
}