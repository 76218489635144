* {
  font-family: "OskariG2Regular";
}

html {
  scroll-behavior: smooth;
}
.font-label {
  color: #666;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  // @apply text-gray_1 text-[17px] not-italic font-normal leading-[120%];
}

body::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
  transition: all 300ms ease;
}
body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f2f2f2;
}

body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #d2d2d2;
}

.theme-scrollbar {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px;
    transition: all 300ms ease;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #d2d2d2;
    }
  }
}
.theme-scrollbar-sm {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
    transition: all 300ms ease;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #d2d2d2;
    }
  }
}

.homepage {
  background-image: url("../assets/png/homeBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: #fc741e !important;
  scale: 1.75 !important;
}
.ant-radio-wrapper :hover {
  border: none !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #fc741e !important;
}
.ant-radio-checked::after {
  border-color: #fc741e !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #fc741e !important;
  background-color: transparent !important;
}

// .ant-radio-inner ::after :checked {
//   border-color: #fc741e !important;
//   background-color: #fc741e !important;
// }

.ant-radio-wrapper .ant-radio-checked {
  .ant-radio-inner {
    border: 2px solid red;
    background-color: transparent;
  }

  &:hover {
    .ant-radio-inner {
      border-color: transparent !important;
    }
  }
}

.ant-radio-wrapper {
  &:hover {
    .ant-radio-inner {
      border-color: #a4a4a4 !important;
    }
  }
}

.editAvatarTab {
  .ant-tabs-nav-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-tabs-tab {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tabs-ink-bar {
    background-color: #fc741e;
  }
}

.invite {
  .ant-steps .ant-steps-item {
    padding-inline-start: 0px !important;
    white-space: nowrap;
  }
  .ant-steps-item-title::after {
    height: 4px !important;
    left: -8px !important;
  }

  .ant-steps-item-container .ant-steps-item-icon {
    height: 38px !important;
    margin-top: 2px !important  ;
  }
}
.ant-modal:not(.feed-modal):not(.story-viewer-modal) {
  .ant-modal-body {
    max-height: calc(75vh - 180px) !important;
    overflow-y: auto !important;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
      height: 8px;
      transition: all 300ms ease;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        background-color: #f2f2f2;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        background-color: #f2f2f2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #d2d2d2;
      }
    }
  }
}

//mission
.misssion-create-textarea {
  resize: none !important;
  padding: 12px 16px;
  height: 68px !important;
  color: #1f1f1f;
  font-size: 17px;
  font-weight: 400;
  line-height: 1 !important;
  &:hover,
  &:focus {
    border-color: #1f1f1f;
    box-shadow: none;
  }

  &::placeholder {
    color: rgba(102, 102, 102, 0.6);
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
  }
}

.opportunity {
  .ant-steps-icon-dot {
    scale: 2.5 !important;
  }
  .ant-steps-item-active {
    .ant-steps-icon-dot {
      scale: 2 !important;
    }
  }

  .ant-steps
    .ant-steps-item-wait
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #dbd9d9 !important;
  }

  .ant-steps
    .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #fc741e !important;
  }

  .ant-steps
    .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #fc741e !important;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    height: 1px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-tail,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin-inline: 76px 0;
    width: 92%;
    margin: 0;
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #fc741e;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    width: calc(100% - 25px);
    margin-inline-start: 25px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-icon,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin: 0;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-content,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 100%;
    text-align: left;
  }

  .ant-steps-item:last-child {
    .ant-steps-item-content {
      position: absolute;
      display: flex;
      justify-content: flex-end;
    }
    .ant-steps-icon-dot {
      overflow: hidden;
    }
  }
}

.tutorialVideo {
  background-image: url("../assets//png/tutorial.png");
}

.onboarding-carousel {
  border-radius: 20px;
  .owl-stage-outer {
    height: 100% !important;
    border-radius: 0px 24px 24px 0px !important;
    .owl-stage {
      height: 100% !important;

      .owl-item {
        height: 100% !important;
      }
    }
  }
  .owl-carousel .owl-theme {
    height: 100% !important;
  }
  .owl-dots {
    .diamond-dot {
      &.active {
        span {
          background: white !important;
          opacity: 1 !important;
        }
      }
      span {
        background: white !important;
        opacity: 0.4 !important;
      }
    }
  }

  .owl-nav.disabled + .owl-dots {
    margin-top: -49px !important;
    margin-left: 22px !important;
    text-align: left;
  }
}

.phone_number {
  &:focus-visible {
    border: none !important;
    outline: none !important;
  }
}

//spaces
.spaces-carousel.owl-theme {
  .white-dark-dot {
    &:not(:last-child) {
      margin-right: 6px;
    }

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(31, 31, 31, 0.4);
      transform: rotate(45deg);
    }

    &.active span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #1f1f1f;
      transform: rotate(45deg);
    }
  }
}

//login
.login {
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }

  .ant-checkbox-wrapper.custom-checkbox
    .ant-checkbox
    .ant-checkbox-inner:after {
    inset-inline: 6px 4px !important;
  }
}
