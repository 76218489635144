.ant-checkbox-wrapper.custom-checkbox {
  font-family: "OskariG2Regular";

  &:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #1f1f1f;
  }

  &:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #fc741e;
    border-color: transparent;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid #fc741e;
    background: #fc741e;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    width: 5px;
    height: 8px;
    inset-inline: 4px 4px;
  }

  .ant-checkbox {
    display: inline-flex;
    align-self: flex-start;
  }

  .ant-checkbox + span {
    padding: 0;
    margin-left: 12px;
  }
}

.custom-checkbox.checkbox-lg {
  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    width: 6px;
    height: 10px;
    top: 45%;
    margin-inline-start: 0.5px;
  }
}
